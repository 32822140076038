import React from "react";
import { navigate } from "gatsby";
import { PageProps } from "gatsby";
import { Layout, Container } from "components/layout";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";
import { useKey } from "hooks/api";
import { Table, TableFooter } from "components/common/Table";
import { formatDate } from "utils/format";
import { AdminSidebar } from "components/view/AdminSidebar";
import { APIKeyRow } from "components/APIKeyRow";

interface AdminAPIKeyPageProps extends PageProps {}

export default function AdminAPIKeyPage(props: AdminAPIKeyPageProps) {
  const { token } = useAuth();
  const [sortDate, setSortDate] = React.useState("");
  const [sortName, setSortName] = React.useState("");
  const keys = useKey(sortDate, sortName);

  function onSortName(e: boolean) {
    e ? setSortName("asc") : setSortName("desc");
  }

  function onSortDate(e: boolean) {
    e ? setSortDate("asc") : setSortDate("desc");
    setSortName("");
  }

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  return (
    <Layout backgroundMap={true}>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <Table
            data={keys.data}
            pageLength={10}
            columns={[
              {
                title: "Name",
                accesor: "name",
                sort: (e: boolean) => onSortName(e),
              },
              {
                title: "Creation Date",
                accesor: "created_at",
                render: (_, row) =>
                  `${formatDate(row.created_at)} (${
                    row.days_remaining
                  } days remaining)`,
                sort: (e: boolean) => onSortDate(e),
              },
              {
                title: "Key",
                accesor: "apikey",
                render: (_, row) => <APIKeyRow apikey={row.apikey} />,
              },
            ]}
          >
            <TableFooter
              pageLength={10}
              page={Number(keys.pagination?.page) || 0}
              totalRecords={Number(keys.pagination?.totalRecords) || 0}
              onPageChange={keys.onChangePage}
            />
          </Table>
        </Container>
      </Container>
    </Layout>
  );
}
